import axios from "axios";
axios.defaults.withXSRFToken = true;
axios.defaults.xsrfHeaderName = "X-CSRF-TOKEN";

const state = {
  searchResponse:[],//JSON.parse(localStorage.getItem('usersData')) ||
  noResult: false,
  searchAudio: [],
  searchVideo:[],
  showProfiles: false,
  showAudio: false,
  allType: '',
  allFiles:[]
};
const getters = {
  searchResponse: (state) => state.searchResponse,
  searchAudio: (state) => state.searchAudio,
  searchVideo: (state) => state.searchVideo,
  noResult: (state) => state.noResult,
  showProfiles: state => state.showProfiles,
  showAudio: state => state.showAudio,
  allType: state=>state.allType,
  allFiles: state=>state.allFiles
};
const mutations = {
  updateSearchResponse(state, responseData) {
    state.searchResponse = responseData;
  },
  SET_ALL_FILES(state, responseData) {
    state.allFiles = responseData;
  },
  SET_SEARCH_AUDIO(state, responseData) {
    state.searchAudio = responseData;
  },
  SET_SEARCH_VIDEO(state, responseData) {
    state.searchVideo = responseData;
  },
  NO_RESULT(state, value) {
    state.noResult = value;
  },
  SET_SHOW_PROFILES(state, value) {
    state.showProfiles = value;
  },
  SET_SHOW_AUDIO(state, value) {
    state.showAudio = value;
  },
  SET_ALL_TYPE(state, value) {
    state.allType = value
  }
};
const actions = {
  async sendSearchRequest({ commit }, requestData) {
    commit('NO_RESULT', false);
    commit('SET_LOADER', true);
    try {
      axios.defaults.withCredentials = true;
      const response = await axios.post("api/search", requestData, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.data.rowNum==='0') {
        commit('NO_RESULT', true);
      }

      commit("updateSearchResponse", response.data);
      commit('SET_SHOW_PROFILES', true);
      commit('SET_SHOW_AUDIO', false);
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      commit('SET_LOADER', false);
    }
  },
  async searchAudio({ commit }, requestData) {
    commit('NO_RESULT', false);
    commit('SET_LOADER', true);
    try {
      axios.defaults.withCredentials = true;

      const response = await axios.post("api/search/executeAudioSearch", requestData, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      console.log('response.data.rowNum', response.data.rowNum)
      if (response.data.rowNum==='0') {
        commit('NO_RESULT', true);
      }

      commit("SET_SEARCH_AUDIO", response.data);
      commit('SET_SHOW_PROFILES', false);
      commit('SET_SHOW_AUDIO', true);

      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      commit('SET_LOADER', false);
    }
  },
  async searchMedia({ commit }, requestData) {
    // console.log('requestData before try', requestData)
    commit('SET_ALL_TYPE','')
    commit('NO_RESULT', false);
    commit('SET_LOADER', true);
    try {
      axios.defaults.withCredentials = true;

      const response = await axios.post("api/search/executeMediaSearch", requestData, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      console.log('response.data.rowNum', response.data.rowNum)
      if (response.data.rowNum==='0') {
        commit('NO_RESULT', true);
        
      }
      const results = response.data?.outIO?.ListOfSearchMediaResult?.Result || [];
    
    // Распаковываем вложенные файлы
    const allFiles = results.flatMap(item => item.ListOfFile?.File || []);
      console.log('allFiles',allFiles)
    // Разделяем файлы по типу
    const audioFiles = allFiles.filter(file => file.FileType === "Audio");
    const videoFiles = allFiles.filter(file => file.FileType === "Video");
      // console.log('audioFiles',audioFiles)
      // console.log('videoFiles', videoFiles)
      commit("SET_ALL_FILES", audioFiles);
      commit("SET_SEARCH_AUDIO", audioFiles);
      commit("SET_USER_AUDIO", audioFiles);
      commit("SET_SEARCH_VIDEO", videoFiles);
      
      
      // commit('SET_SHOW_PROFILES', false);
      commit('SET_SHOW_AUDIO', true);

      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      commit('SET_LOADER', false);
    }
  },
};


export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,

};
