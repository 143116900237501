import axios from 'axios';
axios.defaults.withXSRFToken = true;
axios.defaults.xsrfHeaderName = "X-CSRF-TOKEN";

export default {

   async getUserTalant(context, code) {
    try {

      const apiUrl = '/api/user/getUserTalant'; // Замените на ваш URL API
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

      const response = await axios.get(`${apiUrl}?code=${code}`,{headers});

      const talantInfo = response.data;

      const talantObj = talantInfo.outIO?.ListOfUserTalant?.UserTalant?.[0] ?? {};
      const talantTools = talantObj.ListOfTool?.Tool ?? [];
      const talantStyles = talantObj.ListOfStyle?.Style ?? [];
      const talantService = talantObj.ListOfTalantService?.TalantService ?? [];
      // const oldTalant = {...talantObj};

      context.commit('SET_TALANT', talantObj);
      context.commit('SET_TALANTTOOLS', talantTools);
      context.commit('SET_TALANTSTYLES', talantStyles);
      context.commit('SET_TALANTSERVICELIST', talantService);
    } catch (error) {
      context.commit('SET_TALANT', {});
      context.commit('SET_TALANTTOOLS', []);
      context.commit('SET_TALANTSTYLES', []);
    }
  },
  async updateTalant(context, code) {
    try {
      const apiUrl = '/api/user/syncTalant';
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };

      const tools = Array.isArray(context.getters.talantTools) && context.getters.talantTools.length > 0
        ? { Tool: context.getters.talantTools.map(obj => ({ Code: obj.Code })) }
        : {};

      const styles = Array.isArray(context.getters.talantStyles) && context.getters.talantStyles.length > 0
        ? { Style: context.getters.talantStyles.map(obj => ({ Code: obj.Code })) }
        : {};

      const talant = context.getters.Talant || {};
      const data = {
        InIO: {
          IntObjectName: "UserTalant",
          ListOfUserTalant: {
            UserTalant: [
              {
                Code: talant.Code,
                CreativeName: talant.CreativeName,
                About: talant.About,
                ListOfTool: tools,
                ListOfStyle: styles,
              },
            ],
          },
        },
      };
      console.log(apiUrl, data, headers )
      const response = await axios.post(apiUrl, data, { headers });
      const userInfo = response.data;

      if (userInfo.outIO?.ListOfUserInfo?.UserInfo?.length) {
        const userObj = userInfo.outIO.ListOfUserInfo.UserInfo[0];
        context.commit('SET_CURRENT_USER', userObj);
      }

      await context.dispatch('showMessage', {
        type: "success",
        message: "Данные таланта успешно обновлены",
      });
    } catch (error) {
      await context.dispatch('showMessage', {
        type: "error",
        message: "Ошибка обновления таланта пользователя",
      });
      console.error('Ошибка обновления таланта пользователя:', error.message);
    }
  },

  async getServiceList(context, payload){
     try {
        const apiUrl = '/api/public/getServiceList';
        const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
        const data = {
          "languageCode": "RUS",
          "talantCode": payload
        }
        const response = await axios.post(apiUrl, data, {headers});
        const serviceData = response.data;
        context.commit('SET_SERVICELIST', serviceData.outIO.ListOfServiceList.Service);

     } catch (error) {
        console.log(error)
       throw error
     }
  },

  async addUserTalantService (context, payload){
     try {
       const apiUrl = '/api/user/addUserTalantService';
       const headers = {
         'Accept': 'application/json',
         'Content-Type': 'application/json'
       }
       const data = {
         "talantCode": payload.talantCode,
         "serviceCode": payload.serviceCode,
         "serviceName": payload.serviceName,
         "cost": payload.costByAgreeFlag ? "" : payload.cost,
         "unit": payload.costByAgreeFlag ? "" : payload.unit,
         "costByAgreeFlag": payload.costByAgreeFlag ? 'Y' : 'N'
       }
       const response = await axios.post(apiUrl, data, {headers});
       const serviceData = response.data;
       await context.dispatch('showMessage', {type: "success", message: "Услуга успешно добавлена"});

     } catch (error) {
       await context.dispatch('showMessage', {type: "error", message: "Ошибка добавления"});
       throw error;
     }
  },
  async updateUserTalantService (context, payload){
    try {
      const apiUrl = '/api/user/updateUserTalantService';
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
      const data = {
        "talantCode": payload.talantCode,
        "serviceCode": payload.serviceCode,
        "serviceId": payload.serviceId,
        "serviceName": payload.serviceName,
        "cost": payload.costByAgreeFlag ? "" : payload.cost,
        "unit": payload.costByAgreeFlag ? "" : payload.unit,
        "costByAgreeFlag": payload.costByAgreeFlag ? 'Y' : 'N'
      }
      const response = await axios.post(apiUrl, data, {headers});
      const serviceData = response.data;
      await context.dispatch('showMessage', {type: "success", message: "Услуга успешно изменена"});

    } catch (error) {
      await context.dispatch('showMessage', {type: "error", message: "Ошибка добавления"});
      throw error;
    }
  },
  async deleteUserTalantService (context, payload) {
    try {
      const apiUrl = '/api/user/deleteUserTalantService';
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
      const data = {
        "talantCode": payload.talantCode,
        "serviceId": payload.serviceId,
      }
      const response = await axios.post(apiUrl, data, {headers});
      const serviceData = response.data;
      await context.dispatch('showMessage', {type: "success", message: "Услуга успешно удалена"});

    } catch (error) {
      await context.dispatch('showMessage', {type: "error", message: "Ошибка добавления"});
      throw error;
    }
  }



}
